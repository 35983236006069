import React from "react"
import "./FloatingCTAShell.scss"
import { Container } from "react-bootstrap"
import MobileIcon from "@components/icons/MobileIcon"
import { addRollWhatsapp, getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import { Link } from "gatsby"
import MailIcon from "@components/icons/MailIcon"

const FloatingCTAShell = ({ children, message, email, name}) => {
  const { phone,whatsapp } = useBrandInfo()

  return (
    <div className="floating-cta-shell-wrap">
      <Container className="floating-cta-shell">
        <div className="floating-section">
          {children || (
            <>
              <Link to="/" className="button button-orange">
                <span>Email</span>
                <MailIcon color="white" />
              </Link>
              <a
                href={`tel:${phone}`}
                className="button button-orange"
                onClick={() => gtagReportConversion(`tel:${phone}`)}
              >
                <span>Call</span>
                <MobileIcon color="white" />
              </a>
              <a
                href={getWhatsAppURL("971505389860", message, email, whatsapp, name)}
                className="button button-gray whatsapp-link"
                target="_blank"
                rel="noreferrer"
                onClick={()=> addRollWhatsapp()}
              >
                <span>WhatsApp</span>
                <WhatsAppIcon />
              </a>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default FloatingCTAShell
